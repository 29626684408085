import * as _dom3 from "element-ui/lib/utils/dom";
var _dom2 = "default" in _dom3 ? _dom3.default : _dom3;
var exports = {};
exports.__esModule = true;
var _dom = _dom2;
function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}
var Transition = function () {
  function Transition() {
    _classCallCheck(this, Transition);
  }
  Transition.prototype.beforeEnter = function beforeEnter(el) {
    (0, _dom.addClass)(el, "collapse-transition");
    if (!el.dataset) el.dataset = {};
    el.dataset.oldPaddingTop = el.style.paddingTop;
    el.dataset.oldPaddingBottom = el.style.paddingBottom;
    el.style.height = "0";
    el.style.paddingTop = 0;
    el.style.paddingBottom = 0;
  };
  Transition.prototype.enter = function enter(el) {
    el.dataset.oldOverflow = el.style.overflow;
    if (el.scrollHeight !== 0) {
      el.style.height = el.scrollHeight + "px";
      el.style.paddingTop = el.dataset.oldPaddingTop;
      el.style.paddingBottom = el.dataset.oldPaddingBottom;
    } else {
      el.style.height = "";
      el.style.paddingTop = el.dataset.oldPaddingTop;
      el.style.paddingBottom = el.dataset.oldPaddingBottom;
    }
    el.style.overflow = "hidden";
  };
  Transition.prototype.afterEnter = function afterEnter(el) {
    // for safari: remove class then reset height is necessary
    (0, _dom.removeClass)(el, "collapse-transition");
    el.style.height = "";
    el.style.overflow = el.dataset.oldOverflow;
  };
  Transition.prototype.beforeLeave = function beforeLeave(el) {
    if (!el.dataset) el.dataset = {};
    el.dataset.oldPaddingTop = el.style.paddingTop;
    el.dataset.oldPaddingBottom = el.style.paddingBottom;
    el.dataset.oldOverflow = el.style.overflow;
    el.style.height = el.scrollHeight + "px";
    el.style.overflow = "hidden";
  };
  Transition.prototype.leave = function leave(el) {
    if (el.scrollHeight !== 0) {
      // for safari: add class after set height, or it will jump to zero height suddenly, weired
      (0, _dom.addClass)(el, "collapse-transition");
      el.style.height = 0;
      el.style.paddingTop = 0;
      el.style.paddingBottom = 0;
    }
  };
  Transition.prototype.afterLeave = function afterLeave(el) {
    (0, _dom.removeClass)(el, "collapse-transition");
    el.style.height = "";
    el.style.overflow = el.dataset.oldOverflow;
    el.style.paddingTop = el.dataset.oldPaddingTop;
    el.style.paddingBottom = el.dataset.oldPaddingBottom;
  };
  return Transition;
}();
exports.default = {
  name: "ElCollapseTransition",
  functional: true,
  render: function render(h, _ref) {
    var children = _ref.children;
    var data = {
      on: new Transition()
    };
    return h("transition", data, children);
  }
};
export default exports;
export const __esModule = exports.__esModule;